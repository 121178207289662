import { useState } from 'react';
import './Hamburger.scss';

function Hamburger(props: {
  onClick: () => void;
  className?: string;
  ariaLabel?: string;
  ariaControls?: string;
  ariaHasPopup?: boolean;
  tabIndex?: number;
  ariaHidden?: boolean;
}) {
  const [active, setActive] = useState(false);

  return (
    <button
      className={`hamburger ${props.className || ''} ${
        active ? 'hamburger--active' : ''
      }`}
      onClick={() => {
        props.onClick();
        setActive(!active);
      }}
      aria-label={props.ariaLabel || 'Menu'}
      aria-expanded={active}
      aria-controls={props.ariaControls || ''}
      aria-haspopup={props.ariaHasPopup || false}
      tabIndex={props.tabIndex || 0}
      aria-hidden={props.ariaHidden}
    >
      <div className='hamburger__line hamburger__line--top' />
      <div className='hamburger__line hamburger__line--middle' />
      <div className='hamburger__line hamburger__line--bottom' />
    </button>
  );
}

export default Hamburger;
