import React, { Component } from 'react';
import './ErrorBoundary.scss';
import IconButton from '../icon-button/IconButton';
import { faBug, faHome, faRefresh } from '@fortawesome/free-solid-svg-icons';

class ErrorBoundary extends Component<any, any> {
  state: {
    hasError: boolean;
    error: Error | null;
    errorInfo: React.ErrorInfo | null;
  } = {
    hasError: false,
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.log('ErrorBoundary', error, errorInfo);

    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <h1 className="error-boundary__title">Something went wrong</h1>
          <details className="error-boundary__details">
            <summary>{this.state.error && this.state.error.toString()}</summary>
            <br />
            <code>{this.state.errorInfo?.componentStack}</code>
          </details>

          <div className="error-boundary__buttons">
            <IconButton
              className="error-boundary__buttons__button"
              label="Refresh"
              icon={faRefresh}
              onClick={() => {
                window.location.reload();
              }}
            />
            <IconButton
              className="error-boundary__buttons__button"
              label="Go home"
              icon={faHome}
              onClick={() => {
                window.location.href = '/';
              }}
            />
            <IconButton
              className="error-boundary__buttons__button"
              label="Report bug"
              icon={faBug}
              onClick={() => {
                window.location.href = `mailto:jack@lusher.digital?subject=Error%20on%20talkingperformance.co.uk&body=${encodeURIComponent(
                  `${this.state.error?.toString()}
                  \n\nComponent Stack: ${this.state.errorInfo?.componentStack}`
                )}`;
              }}
            />
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
