import '../Errors.scss';

function NotFound() {
  return (
    <main className="layout-main">
      <div className="error">
        <h1 className="error-header">404</h1>
        <p className="error-description">Page not found</p>
        <a href="/" className="error-link">
          Back to Homepage
        </a>
      </div>
    </main>
  );
}

export default NotFound;
