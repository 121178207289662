import { Outlet } from 'react-router-dom';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import { useUser } from '../../providers/UserProvider';
import './Layout.scss';
import ErrorBoundary from '../../widgets/error-boundary/ErrorBoundary';

function Layout() {
  const { user } = useUser();

  return (
    <div className="layout">
      <Header />
      <div
        className="layout__content"
        style={{
          display: user === undefined ? 'none' : 'flex',
        }}
      >
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </div>
      {user === undefined && <div className="layout__loading">Loading...</div>}
      <Footer />
    </div>
  );
}

export default Layout;
