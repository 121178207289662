import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faMicrophoneLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Editable from '../../widgets/editable/Editable';
import FirebaseImage from '../../widgets/firebase-image/FirebaseImage';
import './FounderCard.scss';
import ErrorBoundary from '../../widgets/error-boundary/ErrorBoundary';

function FounderCard() {
  return (
    <div className="founder-card">
      <div className="founder-card__image">
        <ErrorBoundary>
          <FirebaseImage src="about/Jack Green.jpeg" alt="Founder" />
        </ErrorBoundary>
      </div>
      <div className="founder-card__content">
        <h2 className="founder-card__content__name">
          <Editable
            path="pages/about:founder.name"
            defaultValue="Jack Green, OLY"
          />
        </h2>
        <h3 className="founder-card__content__title">
          <Editable path="pages/about:founder.title" defaultValue="Founder" />
        </h3>
        <p className="founder-card__content__bio">
          <Editable path="pages/about:founder.bio" />
        </p>
        <ul className="founder-card__content__links">
          <li className="founder-card__content__links__item">
            <a
              className="founder-card__content__links__item__link"
              href="https://www.linkedin.com/in/jackgreenoly/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedin} />
              <span className="founder-card__content__links__item__link__text">
                /in/jackgreenoly
              </span>
            </a>
          </li>
          <li className="founder-card__content__links__item">
            <Link
              className="founder-card__content__links__item__link"
              to="/speakers/jack-green"
            >
              <FontAwesomeIcon icon={faMicrophoneLines} />
              <span className="founder-card__content__links__item__link__text">
                View Speaker Profile
              </span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default FounderCard;
