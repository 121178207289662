import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCancel,
  faCheck,
  faPencilAlt,
  faUndo,
} from '@fortawesome/free-solid-svg-icons';
import './Editable.scss';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useUser } from '../../providers/UserProvider';
import { firestore } from '../../providers/Firebase';
import {
  deleteField,
  doc,
  DocumentData,
  DocumentReference,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import './Editable.scss';

/**
 * @example
 * <Editable path='pages/home:cards.0.title' />
 * <Editable path='pages/home:cards.0.description' />
 * <Editable path='speakers/jane-doe:bio' />
 */
function Editable(props: {
  inline?: boolean;
  path: string;
  className?: string;
  defaultValue?: string | number | null;
  transform?: (value: string) => any;
  live?: boolean;
  formatter?: (value: string) => string;
}) {
  const { user } = useUser();
  const live = props.live || false;

  // Example: pages/home:cards.0.title
  // Split into:
  // - docRef: pages/home
  // - field: cards.0.title
  const [path, field] = props.path.split(':');
  const docRef = doc(firestore, path);

  const inputRef = useRef<HTMLInputElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const paragraphRef = useRef<HTMLParagraphElement>(null);
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(`${props.defaultValue || undefined}`);

  useEffect(() => {
    async function createField(value = props.defaultValue || '<EDIT ME>') {
      if (!user) {
        return;
      }

      try {
        await updateDoc(docRef, {
          [field]: value,
        });
      } catch (_) {
        await setDoc(docRef, {
          [field]: value,
        });
      }
    }

    let docRef: DocumentReference<DocumentData>;

    try {
      docRef = doc(firestore, path);
    } catch (_) {
      createField();
      return;
    }

    if (!live) {
      getDoc(docRef)
        .then((doc) => {
          const data = doc.data();
          if (!data) {
            createField();
            return;
          }

          const _value = field
            .split('.')
            .reduce((o, i) => o?.[i], data)
            ?.toString();

          if (_value) {
            setValue(_value);
          } else {
            createField();
          }
        })
        .catch((error) => {
          console.error('Error getting document:', error);
        });
      return;
    }

    const unsubscribe = onSnapshot(docRef, (doc) => {
      const data = doc.data();
      if (!data) {
        createField();
        return;
      }
      const _value = field
        .split('.')
        .reduce((o, i) => o![i], data)
        .toString();

      if (_value) {
        setValue(_value);
      } else {
        createField();
      }
    });
    return unsubscribe;
  }, [user, path, field, live, props.defaultValue]);

  if (!user) {
    return (
      <div
        className={`${props.className || ''} ${
          props.inline ? 'editable--inline' : ''
        }`}
      >
        {value.split('\n').map((line, index) => (
          <Fragment key={index}>
            {props.formatter?.(line) || line}
            {index < value.split('\n').length - 1 && <br />}
          </Fragment>
        ))}
      </div>
    );
  }

  function submit() {
    const docRef = doc(firestore, path);
    updateDoc(docRef, {
      [field]: props.transform?.(value) || value,
    });
    setEditing(false);
  }

  function resize(
    element: HTMLTextAreaElement | HTMLInputElement,
    pElement: HTMLParagraphElement
  ) {
    if (!element || !pElement) return;

    // Temporarily unhide the pElement to measure its dimensions.
    const originalDisplay = pElement.style.display;
    pElement.style.display = 'inline-block';

    element.style.width = 'auto';
    element.style.height = 'auto';

    element.style.width = `${pElement.scrollWidth}px`;
    element.style.height = `${pElement.scrollHeight}px`;

    // Restore the original display style of the pElement.
    pElement.style.display = originalDisplay;
  }

  function applyStyles(source: HTMLElement, target: HTMLElement) {
    if (!source || !target) return;

    const sourceStyles = window.getComputedStyle(source);

    // List the style properties you want to copy.
    const propertiesToCopy = [
      'font-family',
      'font-size',
      'font-weight',
      'line-height',
      'color',
      'text-align',
      'text-decoration',
      'background-color',
      'padding',
      'margin',
    ];

    propertiesToCopy.forEach((property) => {
      target.style.setProperty(
        property,
        sourceStyles.getPropertyValue(property)
      );
    });
  }

  function doStyling() {
    if (!paragraphRef.current) {
      return;
    }

    if (props.inline && inputRef.current) {
      applyStyles(inputRef.current, paragraphRef.current);
      resize(inputRef.current, paragraphRef.current);
    } else if (textAreaRef.current) {
      applyStyles(textAreaRef.current, paragraphRef.current);
      resize(textAreaRef.current, paragraphRef.current);
    }
  }

  return (
    <div
      className={`editable ${editing ? 'editable--editing' : ''} ${
        props.className || ''
      } ${props.inline ? 'editable--inline' : ''}`}
    >
      {editing &&
        (props.inline ? (
          <input
            ref={inputRef}
            className="editable__content editable__input"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        ) : (
          <textarea
            ref={textAreaRef}
            className="editable__content editable__input"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              doStyling();
            }}
          />
        ))}
      <p
        ref={paragraphRef}
        className={`editable__content editable__paragraph ${
          editing ? 'editable--hidden' : ''
        }`}
      >
        {(textAreaRef.current?.value || value)
          .split('\n')
          .map((line, index) => (
            <Fragment key={index}>
              {props.formatter?.(line) || line}
              {index < value.split('\n').length - 1 && <br />}
            </Fragment>
          ))}
      </p>
      <div
        className="editable__controls"
        role="group"
        aria-label="Editable controls"
      >
        {editing && (
          <>
            <button
              className="editable__controls__button editable__controls__button--cancel"
              onClick={() => setEditing(false)}
              aria-label="Cancel"
            >
              <FontAwesomeIcon
                className="editable__controls__button__icon"
                icon={faCancel}
              />
            </button>
            <button
              className="editable__controls__button editable__controls__button--save"
              onClick={submit}
              aria-label="Save"
            >
              <FontAwesomeIcon
                className="editable__controls__button__icon"
                icon={faCheck}
              />
            </button>
          </>
        )}
        {!editing && (
          <>
            <button
              className="editable__controls__button editable__controls__button--edit"
              onClick={() => {
                setEditing(true);
                setTimeout(() => {
                  doStyling();
                }, 0);
              }}
              aria-label="Edit"
            >
              <FontAwesomeIcon
                className="editable__controls__button__icon"
                icon={faPencilAlt}
              />
            </button>
            {props.defaultValue && (
              <button
                className="editable__controls__button editable__controls__button--reset"
                onClick={() => {
                  const result = window.confirm(
                    'Are you sure you want to reset this field to its default value?\nNB: ALL user modifications will be erased and the field will be reset to the value defined in the code.'
                  );

                  if (!result) {
                    return;
                  }

                  updateDoc(docRef, {
                    [field]: deleteField(),
                  });

                  setValue(`${props.defaultValue || '<EDIT ME>'}`);
                }}
                aria-label="Reset"
              >
                <FontAwesomeIcon
                  className="editable__controls__button__icon"
                  icon={faUndo}
                />
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Editable;
