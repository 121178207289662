import {
  Timestamp,
  doc,
  getDoc,
  collection,
  DocumentSnapshot,
} from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { firestore, storage } from '../providers/Firebase';
import NewsItem from '../routes/news/NewsItem';

export const thumbnailSizes = [
  '50x50',
  '100x100',
  '200x200',
  '300x300',
  '500x500',
];

export type ThumbnailImage = {
  name: string;
  alt: string;
  baseURI: string;
  thumbnails: {
    [size: string]: string;
  };
};

export async function docToNewsItem(
  snap: DocumentSnapshot,
  previewLength: number = 100
): Promise<NewsItem> {
  const data = snap.data();
  console.log(data);

  if (!data) {
    throw new Error('No data found');
  }

  if (!data.createdAt) {
    throw new Error('No createdAt found');
  }

  if (!(data.createdAt instanceof Timestamp)) {
    throw new Error('createdAt is not a Timestamp');
  }

  if (!data.createdBy) {
    throw new Error('No createdBy found');
  }

  if (!data.title) {
    throw new Error('No title found');
  }

  if (!data.content) {
    throw new Error('No content found');
  }

  if (!data.image) {
    throw new Error('No image found');
  }

  const usersCollection = collection(firestore, 'users');
  const authorRef = doc(usersCollection, data.createdBy);

  let author;
  try {
    author = (await getDoc(authorRef)).data();
  } catch (e) {
    console.error(e);
  }

  const image = await storagePathToThumbnailImage(data.image);

  console.log(image);

  return {
    id: snap.id,
    title: data.title,
    content: data.content,
    contentPreview: `${data.content.slice(0, previewLength)}...`,
    author: author?.displayName || 'Unknown',
    date: data.createdAt.toDate(),
    type: data.type,
    image: image,
    tags: data.tags,
  };
}

export function sortByDate(a: NewsItem, b: NewsItem): number {
  return b.date.getTime() - a.date.getTime();
}

async function storagePathToThumbnailImage(
  path: string
): Promise<ThumbnailImage> {
  const dir = path.split('/').slice(0, -1).join('/'); // all but last element
  const name = path.split('/').slice(-1)[0].split('.')[0]; // last element (without extension)

  // Get the download URL from the path in Firebase Storage
  const imageRef = ref(storage, path);
  const downloadURL = await getDownloadURL(imageRef);

  // Get the download URL for each thumbnail size
  const thumbnails = (
    await Promise.all(
      thumbnailSizes.map((size) => {
        const thumbnailPath = `${dir}/thumbs/${name}_${size}.webp`;
        return getDownloadURL(ref(storage, thumbnailPath));
      })
    )
  ).reduce((acc, url, i) => {
    acc[thumbnailSizes[i]] = url;
    return acc;
  }, {} as { [size: string]: string });

  return {
    name,
    alt: name,
    baseURI: downloadURL,
    thumbnails,
  };
}

export function dateToLocaleString(date: Date): string {
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    weekday: 'long',
    day: 'numeric',
    hour: 'numeric',
    // minute: 'numeric',
    // hour12: false,
  });
}
