import { useUser } from '../../../providers/UserProvider';
import NavItem from './nav-item/NavItem';
import './NavBar.scss';

function NavBar(props: { hamburgerActive: boolean; ariaHidden: boolean }) {
  const { user, signOut } = useUser();
  const { hamburgerActive, ariaHidden } = props;

  return (
    <nav
      className={`navbar ${hamburgerActive ? 'navbar--active' : ''}`}
      id="navbar"
      role="menubar"
    >
      <NavItem
        href="/speakers"
        label="Speakers"
        ariaLabel="Speakers"
        ariaHidden={ariaHidden}
        tabIndex={0}
      />
      <NavItem
        href="/news"
        label="News"
        ariaLabel="News"
        ariaHidden={ariaHidden}
        tabIndex={0}
      />
      <NavItem
        href="/about"
        label="About"
        ariaLabel="About"
        ariaHidden={ariaHidden}
        tabIndex={0}
      />
      {user === null && (
        <NavItem
          href="/login"
          label="Sign In"
          ariaLabel="Sign In"
          ariaHidden={ariaHidden}
          tabIndex={0}
        />
      )}
      {user && (
        <NavItem
          onClick={signOut}
          label="Sign Out"
          ariaLabel="Sign Out"
          ariaHidden={ariaHidden}
          tabIndex={0}
        />
      )}
    </nav>
  );
}

export default NavBar;
