import { Link } from 'react-router-dom';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../providers/Firebase'; // Update this import path according to your project structure
import Editable from '../editable/Editable';
import { useEffect, useState } from 'react';
import './ImageCard.scss';

function ImageCard(props: {
  titlePath: string;
  defaultTitle: string;
  descriptionPath: string;
  defaultDescription: string;
  image: string;
  link?: string;
  cta?: string;
}) {
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    getDownloadURL(ref(storage, props.image)).then((url) => {
      setImageUrl(url);
    });
  }, [props.image]);

  if (!imageUrl) {
    return null;
  }

  return (
    <div className="image-card">
      <div
        className="image-card__image"
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      />
      <div className="image-card__details">
        <h3 className="image-card__details__title">
          <Editable path={props.titlePath} defaultValue={props.defaultTitle} />
        </h3>
        <Editable
          path={props.descriptionPath}
          defaultValue={props.defaultDescription}
          className="image-card__details__description"
        />
        {props.link && (
          <Link to={props.link} className="button image-card__details__button">
            {props.cta || 'Learn More'}
          </Link>
        )}
      </div>
    </div>
  );
}

export default ImageCard;
