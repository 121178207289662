import { FirebaseError } from 'firebase/app';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../providers/UserProvider';
import './Authenticate.scss';

const ACCOUNT_CREATION_ENABLED = false;

function Authenticate() {
  let { createUserWithPassword, signInWithPassword, resetPassword } = useUser();

  const navigate = useNavigate();

  let [createAccountMode, setCreateAccountMode] = useState(false);
  let [forgotPasswordMode, setForgotPasswordMode] = useState(false);
  let [email, setEmail] = useState('');
  let [password, setPassword] = useState('');
  let [passwordConfirm, setPasswordConfirm] = useState('');
  let [success, setSuccess] = useState(false);
  let [error, setError] = useState('');

  let handleSignInWithPassword = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    if (email !== '' && password !== '') {
      try {
        await signInWithPassword(email, password);
        setError('');
        setSuccess(true);

        navigate('/');
      } catch (e) {
        let error = e as FirebaseError;
        setError(error.message);
        setSuccess(false);
      }
    } else {
      setError('Please fill in all fields.');
    }
  };

  let createAccountWithPassword = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    if (!ACCOUNT_CREATION_ENABLED) {
      setError('Account creation has been disabled.');
      return;
    }

    if (email !== '' && password !== '' && password === passwordConfirm) {
      try {
        await createUserWithPassword(email, password);
        setError('');
        setSuccess(true);
      } catch (e) {
        let error = e as FirebaseError;
        setError(error.message);
        setSuccess(false);
      }
    } else {
      setError('Please fill in all fields.');
    }
  };

  return (
    <main className="layout-main">
      <article className="app-main-article">
        <div className="authenticate">
          {forgotPasswordMode ? (
            <>
              <h1>Forgot Password</h1>
              <p>
                Please enter your email address and we will send you a link to
                reset your password.
              </p>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setForgotPasswordMode(false);
                }}
              >
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
                <button
                  type="button"
                  onClick={async () => {
                    try {
                      await resetPassword(email);
                      setError('');
                      setForgotPasswordMode(false);
                    } catch (e) {
                      let error = e as FirebaseError;
                      setError(error.message);
                    }
                  }}
                >
                  Send
                </button>
                <button
                  onClick={() => setForgotPasswordMode(false)}
                  className="link authenticate-forgot"
                >
                  Go back
                </button>
              </form>
            </>
          ) : (
            <>
              <form
                className="authenticate-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  if (createAccountMode) {
                    createAccountWithPassword(e);
                  } else {
                    handleSignInWithPassword(e);
                  }
                }}
              >
                <input
                  disabled={createAccountMode && !ACCOUNT_CREATION_ENABLED}
                  className="authenticate-form-input"
                  type="email"
                  value={email}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  disabled={createAccountMode && !ACCOUNT_CREATION_ENABLED}
                  className="authenticate-form-input"
                  type="password"
                  value={password}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                {createAccountMode && (
                  <input
                    disabled={!ACCOUNT_CREATION_ENABLED}
                    className="authenticate-form-input"
                    type="password"
                    value={passwordConfirm}
                    placeholder="Confirm Password"
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                  />
                )}
                {createAccountMode ? (
                  <div>
                    <button
                      disabled={!ACCOUNT_CREATION_ENABLED}
                      type="submit"
                      className="button-main"
                    >
                      Create Account
                    </button>
                    <button
                      type="button"
                      onClick={() => setCreateAccountMode(false)}
                    >
                      Sign in
                    </button>
                  </div>
                ) : (
                  <div>
                    <button type="submit">Sign in</button>
                    <button
                      disabled={!ACCOUNT_CREATION_ENABLED}
                      type="button"
                      onClick={() => setCreateAccountMode(true)}
                    >
                      Create Account
                    </button>
                  </div>
                )}
                <button
                  onClick={() => setForgotPasswordMode(true)}
                  className="link authenticate-forgot"
                >
                  Forgot password?
                </button>
                {success && (
                  <span className="authenticate-success">{success}</span>
                )}
                {error.length > 0 && (
                  <span className="authenticate-error">{error}</span>
                )}
              </form>
            </>
          )}
        </div>
      </article>
    </main>
  );
}

export default Authenticate;
