import './Speakers.scss';
import { collection, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import Speaker from './Speaker';
import SpeakerCard from './speaker-card/SpeakerCard';
import 'nouislider/distribute/nouislider.css';
import { firestore } from '../../providers/Firebase';
import Editable from '../../widgets/editable/Editable';
import { useUser } from '../../providers/UserProvider';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../widgets/icon-button/IconButton';

function Speakers() {
  const { user } = useUser();

  const [speakers, setSpeakers] = useState<Speaker[]>();

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(firestore, 'speakers'),
      (snapshot) => {
        const speakers: Speaker[] = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          if (data) {
            speakers.push(data as Speaker);
          }
        });

        setSpeakers(speakers);
      }
    );

    return unsubscribe;
  }, []);

  if (speakers === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <main className="layout-main">
      <div className="speakers">
        <div className="speakers__header">
          <h1>
            <Editable path="pages/speakers:title" defaultValue="Speakers" />
          </h1>
          {user && (
            <IconButton
              anchor
              icon={faAdd}
              label="Add speaker"
              link="/speakers/new"
              className="add-button"
            />
          )}
        </div>
        {speakers.length === 0 ? (
          <span className="speakers__list__not-found">
            No speakers found matching your query.
          </span>
        ) : (
          <div className="speakers__list">
            {speakers.map((speaker, i) => (
              <SpeakerCard key={`${speaker.id}-${i}`} speaker={speaker} />
            ))}
          </div>
        )}
      </div>
    </main>
  );
}

export default Speakers;
