import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NewsItem from '../NewsItem';
import { firestore } from '../../../providers/Firebase';
import './Article.scss';
import { collection, doc, onSnapshot } from 'firebase/firestore';
import { dateToLocaleString, docToNewsItem } from '../../../utils/news';
import Editable from '../../../widgets/editable/Editable';

function Article() {
  const { id } = useParams<{ id: string }>();

  const [article, setArticle] = useState<NewsItem | null>(null);

  useEffect(() => {
    const _collection = collection(firestore, 'articles');
    const _doc = doc(_collection, id);
    const unsubscribe = onSnapshot(_doc, (snap) => {
      docToNewsItem(snap, 0).then((newsItem) => {
        setArticle(newsItem);
      });
    });
    return unsubscribe;
  }, [id]);

  if (!article) {
    return <div className="article">Loading...</div>;
  }

  return (
    <div className={`article article--${article?.type}`}>
      {article?.image && (
        <div className="article__image">
          <img src={article.image.baseURI} alt={article?.title} />
        </div>
      )}
      <h1 className="article__title">
        <Editable
          inline
          path={`articles/${id}:title`}
          defaultValue={article?.title}
        />
      </h1>
      <div className="article__meta">
        <span className="article__meta__author">
          By{' '}
          <Editable
            inline
            path={`articles/${id}:author`}
            defaultValue={article?.author}
          />
        </span>
        <span className="article__meta__date">
          Posted {dateToLocaleString(article?.date)}
        </span>
      </div>
      <Editable
        className="article__content"
        path={`articles/${id}:content`}
        defaultValue={article?.content}
      />
    </div>
  );
}

export default Article;
