import NewsItem from '../NewsItem';
import './NewsCard.scss';

function NewsCard(props: NewsItem) {
  console.log(props.id, props.image);
  return (
    <a href={`/news/${props.id}`} className="news-card-link">
      <div className={`news-card news-card--${props.type}`}>
        <span className={`news-card__type news-card__type--${props.type}`}>
          {props.type.toUpperCase()}
        </span>
        {props.image && (
          <div
            className="news-card__image"
            style={{ backgroundImage: `url(${props.image.baseURI})` }}
            aria-hidden="true"
          />
        )}
        <div className="news-card__content">
          <h2 className="news-card__content__title">{props.title}</h2>
          <p className="news-card__content__preview">{props.contentPreview}</p>
          {props.tags && (
            <div className="news-card__content__tags">
              {props.tags.map((tag) => (
                <span key={tag} className="news-card__content__tags__tag">
                  {tag}
                </span>
              ))}
            </div>
          )}
          <div className="news-card__content__info">
            <span className="news-card__content__info__author">
              By {props.author}
            </span>
            <span className="horizontal-divider" aria-hidden="true" />
            <span className="news-card__content__info__date">
              Posted {props.date.toLocaleDateString()}
            </span>
          </div>
        </div>
      </div>
    </a>
  );
}

export default NewsCard;
