import { useState } from 'react';
import logo from '../../images/logo.png';
import useWindowDimensions from '../../providers/WindowDimensions';
import Hamburger from '../../widgets/hamburger/Hamburger';
import './Header.scss';
import NavBar from './navbar/NavBar';

function Header() {
  const { width } = useWindowDimensions();
  const [hamburgerActive, setHamburgerActive] = useState(false);

  function ariaHidden() {
    return width < 768 ? !hamburgerActive : false;
  }

  return (
    <header className="app-header">
      <div className="app-header__inner">
        <a href="/" className="app-header__logo" aria-label="Home">
          <img src={logo} className="app-header__logo__image" alt="logo" />
        </a>
        {width < 768 && (
          <Hamburger
            className="app-header__hamburger"
            onClick={() => {
              setHamburgerActive(!hamburgerActive);
            }}
            ariaControls="app-header__navbar"
            ariaHasPopup
            tabIndex={0}
            ariaHidden={width >= 768}
          />
        )}
        <NavBar hamburgerActive={hamburgerActive} ariaHidden={ariaHidden()} />
      </div>
    </header>
  );
}

export default Header;
