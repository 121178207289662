import './SpeakerProfile.scss';
import { collection, doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import emptyAvatar from '../../../images/empty-avatar.png';
import NotFound from '../../errors/not-found/NotFound';
import Speaker from '../Speaker';
import { getDownloadURL, ref } from 'firebase/storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import SpeakerQuoteForm from '../../../components/speaker-quote-form/SpeakerQuoteForm';
import { firestore, storage } from '../../../providers/Firebase';
import Editable from '../../../widgets/editable/Editable';
import { useUser } from '../../../providers/UserProvider';
import Name from '../../../utils/Name';

function SpeakerProfile() {
  const { user } = useUser();
  const { speakerID } = useParams();

  const [speaker, setSpeaker] = useState<Speaker | null>();
  const [avatar, setAvatar] = useState<any>();

  useEffect(() => {
    if (!speakerID) return;

    const c = collection(firestore, 'speakers');
    const d = doc(c, speakerID);

    const unsubscribe = onSnapshot(d, (snapshot) => {
      if (!snapshot.exists()) {
        setSpeaker(null);
        return;
      }

      const data = snapshot.data();
      if (data) {
        setSpeaker(data as Speaker);
      }

      if (data?.avatar) {
        // const pathParts = data.avatar.split('/');
        // `${pathParts[0]}/${pathParts[1]}/thumbs/${pathParts[2].split('.')[0]}_200x200.webp`

        const r = ref(storage, data.avatar);
        getDownloadURL(r).then((url) => {
          setAvatar(url);
        });
      }
    });

    return unsubscribe;
  }, [speakerID]);

  if (speaker === undefined) {
    return <div>Loading...</div>;
  }

  if (speaker === null) {
    return <NotFound />;
  }

  return (
    <main className='layout-main'>
      <div className='speaker'>
        <div className='speaker__header'>
          <div className='speaker__header__image-container'>
            <img
              src={avatar || emptyAvatar}
              alt={`${speaker.firstName} ${speaker.lastName}`}
              className='speaker__header__image-container__image'
              width={200}
              height={200}
            />
          </div>
          <div className='speaker__header__info'>
            <h1 className='speaker__header__info__name'>
              <Name speaker={speaker} />
            </h1>
            <div className='speaker__header__info__rates'>
              <h2 className='speaker__header__info__rates__label'>
                <Editable
                  inline
                  path={`pages/speakers:ratesLabel`}
                  defaultValue='Event rates'
                />
              </h2>
              <div className='speaker__header__info__rates__value'>
                <span className='speaker__header__info__rates__base-rate'>
                  £
                  <Editable
                    inline
                    live
                    path={`speakers/${speakerID}:baseRate`}
                    defaultValue={speaker.baseRate}
                  />
                </span>
                <span className='speaker__header__info__rates__separator'>
                  {' '}
                  to{' '}
                </span>
                <span className='speaker__header__info__rates__max-rate'>
                  £
                  <Editable
                    inline
                    live
                    path={`speakers/${speakerID}:maxRate`}
                    defaultValue={speaker.maxRate}
                  />
                </span>
              </div>
            </div>
            {speaker.topics && (
              <div className='speaker__header__info__topics'>
                <h2 className='speaker__header__info__topics__label'>Topics</h2>
                <ul className='speaker__header__info__topics__list'>
                  {speaker.topics.map((topic, i) => (
                    <li
                      key={i}
                      className='speaker__header__info__topics__list__item'
                    >
                      {topic}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className='speaker__profile'>
          <div className='speaker__profile__bio'>
            <h2 className='speaker__profile__bio__label'>
              <Editable
                inline
                path={`pages/speakers:bioLabel`}
                defaultValue='Bio'
              />
            </h2>
            {user ? (
              <Editable
                className='speaker__profile__bio__value'
                path={`speakers/${speakerID}:bio`}
                defaultValue={speaker.bio}
              />
            ) : (
              <p className='speaker__profile__bio__value'>{speaker.bio}</p>
            )}
          </div>
          {(speaker.website || speaker.twitter || speaker.linkedin) && (
            <div className='speaker__profile__links'>
              <h2 className='speaker__profile__links__label'>
                <Editable
                  inline
                  path={`pages/speakers:linksLabel`}
                  defaultValue='Links'
                />
              </h2>
              <ul className='speaker__profile__links__list'>
                {speaker.website && (
                  <li className='speaker__profile__links__list__item'>
                    <a
                      href={`https://${speaker.website}`}
                      target='_blank'
                      rel='noreferrer'
                      className='speaker__profile__links__list__item__link'
                    >
                      <FontAwesomeIcon icon={faLink} />
                      &nbsp;{speaker.website}
                    </a>
                  </li>
                )}
                {speaker.twitter && (
                  <li className='speaker__profile__links__list__item'>
                    <a
                      href={`https://twitter.com/${speaker.twitter}`}
                      target='_blank'
                      rel='noreferrer'
                      className='speaker__profile__links__list__item__link'
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                      &nbsp;@{speaker.twitter}
                    </a>
                  </li>
                )}
                {speaker.linkedin && (
                  <li className='speaker__profile__links__list__item'>
                    <a
                      href={speaker.linkedin}
                      target='_blank'
                      rel='noreferrer'
                      className='speaker__profile__links__list__item__link'
                    >
                      <FontAwesomeIcon icon={faLinkedin} />
                      &nbsp;{speaker.linkedin}
                    </a>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
      {speaker && speakerID && <SpeakerQuoteForm speaker={speakerID} />}
    </main>
  );
}

export default SpeakerProfile;
