import './App.scss';
import { Route, Routes } from 'react-router-dom';
import Layout from './routes/layout/Layout';
import Home from './routes/home/Home';
import NotFound from './routes/errors/not-found/NotFound';
import Authenticate from './components/authenticate/Authenticate';
import NewSpeaker from './routes/speakers/new/NewSpeaker';
import { useUser } from './providers/UserProvider';
import SpeakerProfile from './routes/speakers/speaker-profile/SpeakerProfile';
import Speakers from './routes/speakers/Speakers';
import News from './routes/news/News';
import AddNews from './routes/news/add/AddNews';
import Article from './routes/news/article/Article';
import About from './routes/about/About';
import ErrorBoundary from './widgets/error-boundary/ErrorBoundary';
import PrivacyPolicy from './routes/privacy-policy/PrivacyPolicy';

function App() {
  const { user } = useUser();

  return (
    <ErrorBoundary>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/login" element={<Authenticate />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/about" element={<About />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/add" element={<AddNews />} />
          <Route path="/news/:id" element={<Article />} />
          <Route path="/speakers" element={<Speakers />} />
          {user && <Route path="/speakers/new" element={<NewSpeaker />} />}
          <Route path="/speakers/:speakerID" element={<SpeakerProfile />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </ErrorBoundary>
  );
}

export default App;
