import './NavItem.scss';

function NavItem(props: {
  href?: string;
  onClick?: () => void;
  label: string;
  ariaLabel: string;
  ariaHidden: boolean;
  tabIndex: number;
}) {
  const active = window.location.pathname === props.href;

  console.assert(
    props.href || props.onClick,
    'NavItem must have either href or onClick'
  );

  if (props.onClick) {
    return (
      <button
        role="menuitem"
        className={`nav-item ${active ? 'nav-item--active' : ''}`}
        onClick={props.onClick}
        aria-label={props.ariaLabel}
        aria-hidden={props.ariaHidden}
        tabIndex={props.tabIndex}
      >
        {props.label}
      </button>
    );
  }

  return (
    <a
      role="menuitem"
      className={`nav-item button ${active ? 'nav-item--active' : ''}`}
      href={props.href}
      aria-label={props.ariaLabel}
      aria-hidden={props.ariaHidden}
      tabIndex={props.tabIndex}
    >
      {props.label}
    </a>
  );
}

export default NavItem;
