import { faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { deleteDoc, doc } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { useEffect, useState } from 'react';
import emptyAvatar from '../../../images/empty-avatar.png';
import { firestore, storage } from '../../../providers/Firebase';
import { useUser } from '../../../providers/UserProvider';
import Name from '../../../utils/Name';
import Editable from '../../../widgets/editable/Editable';
import IconButton from '../../../widgets/icon-button/IconButton';
import Speaker from '../Speaker';
import './SpeakerCard.scss';
import ErrorBoundary from '../../../widgets/error-boundary/ErrorBoundary';
import { numberFormatter } from '../../../utils/number';

function SpeakerCard(props: { speaker: Speaker }) {
  const { user } = useUser();
  const { speaker } = props;

  const [avatar, setAvatar] = useState<string>();

  useEffect(() => {
    (async () => {
      if (speaker.avatar) {
        try {
          const size = 200;
          const split = speaker.avatar.split('/');
          const path = `${split.slice(0, split.length - 1).join('/')}/thumbs/${
            split[split.length - 1].split('.')[0]
          }_${size}x${size}.webp`;
          const avatarRef = ref(storage, path);
          const downloadURL = await getDownloadURL(avatarRef);
          setAvatar(downloadURL);
        } catch (e) {
          setAvatar(emptyAvatar);
        }
      } else {
        setAvatar(emptyAvatar);
      }
    })();
  }, [speaker]);

  return (
    <ErrorBoundary>
      <div className="speaker-card">
        <img
          src={avatar}
          alt={`${speaker.firstName} ${speaker.lastName}`}
          className="speaker-card__image"
        />
        <div className="speaker-card__info">
          <h2 className="speaker-card__info__name">
            <Name speaker={speaker} />
          </h2>
          <div className="speaker-card__info__rates">
            <div className="speaker-card__info__rates__value">
              <span className="speaker-card__info__rates__base-rate">
                £
                <Editable
                  inline
                  live
                  path={`speakers/${speaker.id}:baseRate`}
                  formatter={numberFormatter}
                />
              </span>
              <span className="speaker-card__info__rates__separator">-</span>
              <span className="speaker-card__info__rates__max-rate">
                £
                <Editable
                  inline
                  live
                  path={`speakers/${speaker.id}:maxRate`}
                  formatter={numberFormatter}
                />
              </span>
            </div>
          </div>
          {speaker.tagline && (
            <p className="speaker-card__info__description">{speaker.tagline}</p>
          )}
        </div>
        <div className="speaker-card__actions">
          <IconButton
            anchor
            icon={faEye}
            label="View"
            link={`/speakers/${speaker.id}`}
            className="button speaker-card__actions__view"
          />
          {user && (
            <IconButton
              anchor
              icon={faTrashAlt}
              label="Delete"
              onClick={async () => {
                const confirmed = window.confirm(
                  `Are you sure you want to delete ${speaker.firstName} ${speaker.lastName}?`
                );

                if (!confirmed) {
                  return;
                }

                const docRef = doc(firestore, `speakers/${speaker.id}`);
                await deleteDoc(docRef);

                window.location.reload();
              }}
              className="button speaker-card__actions__delete"
            />
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default SpeakerCard;
