import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { httpsCallable } from 'firebase/functions';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { functions } from '../../providers/Firebase';
import Editable from '../../widgets/editable/Editable';
import './SpeakerQuoteForm.scss';

function SpeakerQuoteForm(props: { speaker: string }) {
  const { speakerID } = useParams();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [message, setMessage] = useState('');

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState<string>();

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      // Invoke the Firebase function to send the email
      const sendSpeakerQuoteEmail = httpsCallable(
        functions,
        'sendSpeakerQuoteEmail'
      );

      await sendSpeakerQuoteEmail({
        firstName,
        lastName,
        email,
        phone,
        company,
        jobTitle,
        message,
        speakerID,
      });

      setSubmitted(true);
      setError(undefined);

      setFirstName('');
      setLastName('');
      setEmail('');
      setPhone('');
      setCompany('');
      setJobTitle('');
      setMessage('');
    } catch (e: any) {
      setError(e.message);
    }
  }

  return (
    <div className="quote-form">
      <form onSubmit={submit}>
        <table>
          <caption>
            <Editable
              path="pages/speakers:quote-form.caption"
              defaultValue="Request a Speaker Quote"
            />
          </caption>
          <tbody>
            <tr>
              <td className="form__field">
                <label className="form__field__label" htmlFor="firstName">
                  <Editable
                    inline
                    path="pages/speakers:quote-form.first-name"
                    defaultValue="First Name"
                  />
                </label>
                <input
                  className="form__field__input"
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  pattern="[A-Za-z \-']{1,32}"
                  required
                />
              </td>
              <td className="form__field">
                <label className="form__field__label" htmlFor="lastName">
                  <Editable
                    inline
                    path="pages/speakers:quote-form.last-name"
                    defaultValue="Last Name"
                  />
                </label>
                <input
                  className="form__field__input"
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  pattern="[A-Za-z \-']{1,32}"
                  required
                />
              </td>
            </tr>
            <tr>
              <td className="form__field">
                <label className="form__field__label" htmlFor="email">
                  <Editable
                    inline
                    path="pages/speakers:quote-form.email-label"
                    defaultValue="Email"
                  />
                </label>
                <input
                  className="form__field__input"
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$"
                  required
                />
              </td>
              <td className="form__field">
                <label className="form__field__label" htmlFor="phone">
                  <Editable
                    inline
                    path="pages/speakers:quote-form.phone-label"
                    defaultValue="Phone"
                  />
                </label>
                <input
                  className="form__field__input"
                  type="tel"
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  pattern="[0-9]{10,12}"
                />
              </td>
            </tr>
            <tr>
              <td className="form__field">
                <label className="form__field__label" htmlFor="company">
                  <Editable
                    inline
                    path="pages/speakers:quote-form.company-label"
                    defaultValue="Company"
                  />
                </label>
                <input
                  className="form__field__input"
                  type="text"
                  id="company"
                  name="company"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  pattern="[A-Za-z0-9 .\-']{1,32}"
                />
              </td>
              <td className="form__field">
                <label className="form__field__label" htmlFor="jobTitle">
                  <Editable
                    inline
                    path="pages/speakers:quote-form.job-title-label"
                    defaultValue="Job Title"
                  />
                </label>
                <input
                  className="form__field__input"
                  type="text"
                  id="jobTitle"
                  name="jobTitle"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                  pattern="[A-Za-z0-9 .\-']{1,32}"
                />
              </td>
            </tr>
            <tr>
              <td className="form__field" colSpan={2}>
                <label className="form__field__label" htmlFor="message">
                  <Editable
                    inline
                    path="pages/speakers:quote-form.message-label"
                    defaultValue="Message"
                  />
                </label>
                <textarea
                  className="form__field__input"
                  id="message"
                  name="message"
                  rows={5}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />
              </td>
            </tr>
            <tr>
              <td className="form__field privacy-policy" colSpan={2}>
                <input type="checkbox" id="terms" name="terms" required />
                <label htmlFor="terms" className="form__field__label">
                  I agree to the{' '}
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </label>
              </td>
            </tr>
            <tr>
              <td className="form__field">
                {error && <p className="form-error">{error}</p>}
                {submitted && (
                  <p className="form-success">
                    Your message has been sent. We will get back to you shortly.
                  </p>
                )}
              </td>
              <td className="form__field align-right">
                <button type="submit">
                  <FontAwesomeIcon icon={faPaperPlane} />
                  &nbsp;Send
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
}

export default SpeakerQuoteForm;
