import { collection, DocumentSnapshot, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { firestore } from '../../providers/Firebase';
import { useUser } from '../../providers/UserProvider';
import IconButton from '../../widgets/icon-button/IconButton';
import './News.scss';
import NewsItem from './NewsItem';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import Editable from '../../widgets/editable/Editable';
import NewsCard from './news-card/NewsCard';
import { docToNewsItem, sortByDate } from '../../utils/news';

function News() {
  const { user } = useUser();
  const [news, setNews] = useState<NewsItem[]>();

  useEffect(() => {
    const c = collection(firestore, 'articles');
    const unsubscribe = onSnapshot(c, (snapshot) => {
      const newsItems: NewsItem[] = [];

      const promises = snapshot.docs.map(
        async (snap: DocumentSnapshot): Promise<NewsItem> => {
          let newsItem;
          try {
            newsItem = await docToNewsItem(snap, 250);
            newsItems.push(newsItem);
          } catch (e) {
            return Promise.reject(e);
          }

          return newsItem;
        }
      );

      Promise.all(promises).then(() => {
        newsItems.sort(sortByDate);
        setNews(newsItems);
      });
    });

    return unsubscribe;
  }, []);

  return (
    <main className="layout-main">
      <div className="news">
        <div className="news__header">
          <h1>
            <Editable inline path="pages/articles:title" defaultValue="News" />
          </h1>
          {user && (
            <IconButton
              anchor
              icon={faAdd}
              label="Add news article"
              link="/news/add"
              className="add-button"
            />
          )}
        </div>
        {!news ? (
          <p>Loading...</p>
        ) : news.length === 0 ? (
          <div className="no-news">
            <p>
              There is no news to show.
              {user && (
                <>
                  <br />
                  <a href="/news/add">Add an article?</a>
                </>
              )}
            </p>
          </div>
        ) : (
          <ul className="news__list">
            {[...news, ...news, ...news].map((item, index) => (
              <NewsCard key={`${item.id}-${index}`} {...item} />
            ))}
          </ul>
        )}
      </div>
    </main>
  );
}

export default News;
