import './PrivacyPolicy.scss';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>Last Updated: April 28, 2023</p>

      {/* Add the rest of the sections from the Privacy Policy as needed */}
      <section>
        <h2>Introduction</h2>
        <p>
          At Talking Performance ("we," "us," or "our"), we value your privacy
          and are committed to safeguarding the personal information you provide
          to us. This Privacy Policy ("Policy") outlines how we collect, use,
          share, and protect your personal information when you visit or use our
          website and services, as well as your rights and choices regarding the
          use of your personal information.
        </p>
        <p>
          By using our website and services, you agree to the terms of this
          Policy, as well as any updates or modifications we may make. We
          encourage you to periodically review this Policy to stay informed
          about our practices.
        </p>
      </section>

      <section>
        <h2>Information We Collect</h2>
        <p>
          When you use our website or services, we may collect the following
          categories of personal information:
        </p>
        <ul>
          <li>
            <strong>Personal Identifiers:</strong> This includes your first and
            last name, email address, company name, job title, and phone number.
          </li>
          <li>
            <strong>Technical Data:</strong> This includes information about
            your device, browser, operating system, IP address, and other unique
            identifiers.
          </li>
          <li>
            <strong>Usage Data:</strong> This includes information about your
            interactions with our website and services, such as pages viewed,
            links clicked, and other usage patterns.
          </li>
        </ul>
      </section>

      <section>
        <h2>How We Use Your Information</h2>
        <p>
          We use the personal information we collect for the following purposes:
        </p>
        <ul>
          <li>To provide and maintain our website and services.</li>
          <li>
            To personalize and improve your experience on our website and with
            our services.
          </li>
          <li>
            To communicate with you about our services, promotions, and events,
            as well as respond to your inquiries and requests.
          </li>
          <li>
            To analyze and monitor the performance of our website and services,
            as well as identify and resolve any technical issues.
          </li>
          <li>
            To comply with legal and regulatory requirements, and to protect the
            security and integrity of our website and services.
          </li>
        </ul>
      </section>

      <section>
        <h2>Information Sharing</h2>
        <p>
          We may share your personal information with the following categories
          of third parties:
        </p>
        <ul>
          <li>
            <strong>Service Providers:</strong> We may share your information
            with third-party vendors, consultants, and other service providers
            who perform services on our behalf, such as website hosting,
            analytics, and performance monitoring.
          </li>
          <li>
            <strong>Legal and Regulatory Authorities:</strong> We may share your
            information if required to do so by law or to comply with legal
            processes or regulatory obligations.
          </li>
          <li>
            <strong>Business Transfers:</strong> We may share your information
            in connection with a merger, acquisition, reorganization, or sale of
            assets, subject to appropriate confidentiality and privacy measures.
          </li>
          <li>
            <strong>With Your Consent:</strong> We may share your information
            with other parties when we have your explicit consent to do so.
          </li>
        </ul>
      </section>

      <section>
        <h2>Cookies and Similar Technologies</h2>
        <p>
          We use cookies and similar technologies to collect and store
          information about your preferences, improve the performance of our
          website, and analyze usage patterns. You can disable cookies in your
          browser settings, but doing so may affect the functionality of our
          website.
        </p>
      </section>

      <section>
        <h2>Your Rights and Choices</h2>
        <p>
          You may have certain rights regarding the personal information we hold
          about you, subject to local data protection laws. These rights may
          include the right to access, correct, delete, or restrict the
          processing of your personal information, as well as the right to
          object to certain types of processing.
        </p>
        <p>
          To exercise your rights or to obtain more information about our
          privacy practices, please contact us at{' '}
          <a href="mailto:jack@talkingperformance.co.uk">
            jack@talkingperformance.co.uk
          </a>
          .
        </p>
      </section>

      <section>
        <h2>Changes to this Policy</h2>
        <p>
          We may update this Policy from time to time to reflect changes in our
          practices or applicable laws. We will notify you of any material
          changes by posting the updated Policy on our website and updating the
          "Last Updated" date at the top of this page. Your continued use of our
          website and services after we make changes to this Policy is deemed
          acceptance of those changes.
        </p>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about this Policy or our privacy
          practices, please contact us at:
        </p>
        <p>
          <a href="mailto:jack@talkingperformance.co.uk">
            jack@talkingperformance.co.uk
          </a>
        </p>
      </section>
    </div>
  );
}

export default PrivacyPolicy;
