import { initializeApp } from 'firebase/app';
import {
  getAnalytics,
  setAnalyticsCollectionEnabled,
} from 'firebase/analytics';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyB3KK1OtC0oN4pnwEHabDHNnbZWJZCKNyA',
  authDomain: 'talking-performance.firebaseapp.com',
  projectId: 'talking-performance',
  storageBucket: 'talking-performance.appspot.com',
  messagingSenderId: '848765449947',
  appId: '1:848765449947:web:f19eddd37c87e5f92e3efe',
  measurementId: 'G-8Z6M51RX1D',
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);

if (process.env.NODE_ENV === 'development') {
  setAnalyticsCollectionEnabled(analytics, false);
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFirestoreEmulator(firestore, 'localhost', 8080);
  connectStorageEmulator(storage, 'localhost', 9199);
  connectFunctionsEmulator(functions, 'localhost', 5001);
}
