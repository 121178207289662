import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import './IconButton.scss';

function IconButton(props: {
  animated?: boolean; // Whether to use CSS transitions to animate the button
  anchor?: boolean; // Whether to use <a> under the hood
  link?: string; // The link to navigate to
  icon: IconDefinition;
  label?: string;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  ['aria-label']?: string;
  ariaControls?: string;
  ariaHasPopup?: boolean;
  title?: string;
  tabIndex?: number;
  ['aria-hidden']?: boolean;
  role?: string;
}) {
  if (props.anchor) {
    return (
      <a
        className={`button icon-button ${props.className || ''} ${
          props.animated ? 'button-animated' : ''
        }`}
        href={props.link}
        onClick={props.onClick}
        aria-label={props['aria-label'] || props.label}
        aria-controls={props.ariaControls || ''}
        aria-haspopup={props.ariaHasPopup || false}
        title={props.title}
        tabIndex={props.tabIndex}
        aria-hidden={props['aria-hidden']}
        role={props.role}
      >
        <FontAwesomeIcon icon={props.icon} />
        {props.label && (
          <span className="icon-button-label">{props.label}</span>
        )}
      </a>
    );
  }

  return (
    <button
      onClick={props.onClick}
      className={`icon-button ${props.className || ''} ${
        props.animated ? 'button-animated' : ''
      }`}
      disabled={props.disabled}
      aria-label={props['aria-label'] || props.label}
      aria-controls={props.ariaControls || ''}
      aria-haspopup={props.ariaHasPopup || false}
      title={props.title}
      tabIndex={props.tabIndex}
      aria-hidden={props['aria-hidden']}
      role={props.role}
    >
      <FontAwesomeIcon icon={props.icon} className="icon-button-icon" />
      {props.label && <span className="icon-button-label">{props.label}</span>}
    </button>
  );
}

export default IconButton;
