import { Link } from 'react-router-dom';
import Editable from '../editable/Editable';
import './Hero.scss';

function Hero() {
  return (
    <div className="hero">
      <div className="hero__content">
        <div className="hero-inner">
          <h1 className="hero__title">
            <Editable
              path="pages/home:heading"
              defaultValue="BECOME YOUR BEST"
            />
          </h1>
          <Editable
            path="pages/home:subheading"
            className="hero__subtitle"
            defaultValue="Learn from world-leading speakers in sports, high-performance, leadership and culture development."
          />
          <div className="hero__ctas">
            <Link
              to="/speakers"
              className="button button-animated hero__ctas__cta"
              aria-label="View All Speakers"
            >
              View All Speakers
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
