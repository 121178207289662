import { getDownloadURL, ref } from 'firebase/storage';
import { useEffect, useState } from 'react';
import { storage } from '../../providers/Firebase';

interface FirebaseImageProps {
  src: string; // ex. 'images/1.jpg'
  alt: string;
  [key: string]: any;
}

function FirebaseImage({ src, alt, ...props }: FirebaseImageProps) {
  const [url, setUrl] = useState<string | null>(null);

  console.log('FirebaseImage', src);

  useEffect(() => {
    const storageRef = ref(storage, src);
    getDownloadURL(storageRef).then(setUrl);
  }, [src]);

  return url ? <img src={url} alt={alt} {...props} /> : null;
}

export default FirebaseImage;
