import './Marquee.scss';
import logos from '../../assets/logos/logos.json';
import ErrorBoundary from '../error-boundary/ErrorBoundary';
import { MarqueeItem } from './marquee-item/MarqueeItem';
import Editable from '../editable/Editable';

function Marquee() {
  let firstRow = logos['logos'].slice(0, Math.floor(logos['logos'].length / 2));
  firstRow = [...firstRow, ...firstRow, ...firstRow];

  let secondRow = logos['logos'].slice(Math.floor(logos['logos'].length / 2));
  secondRow = [...secondRow, ...secondRow, ...secondRow];

  return (
    <ErrorBoundary>
      <div className="Marquee__container">
        <h2 className="Marquee__title">
          <Editable path="pages/home:logos.title" defaultValue="Trusted By" />
        </h2>
        <div className="Marquees">
          <div className="Marquee FirstRow">
            {firstRow.map((logo) => (
              <MarqueeItem logo={logo} key={`${logo.name}-${Math.random()}`} />
            ))}
          </div>
          <div className="Marquee SecondRow">
            {secondRow.map((logo) => (
              <MarqueeItem logo={logo} key={`${logo.name}-${Math.random()}`} />
            ))}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default Marquee;
