import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../../providers/Firebase';
import { Logo } from './Logo';
import { useEffect, useState } from 'react';
import './MarqueeItem.scss';

export function MarqueeItem(props: { logo: Logo }) {
  const [isSVG, setIsSVG] = useState<boolean>(false);
  const [image, setImage] = useState<string | null>(null);

  useEffect(() => {
    const filename = props.logo.src.split('.')[0];
    const extension = props.logo.src.split('.')[1];
    const thumbSize = 500;

    let path;

    switch (extension) {
      case 'png':
      case 'jpg':
      case 'jpeg':
        path = `thumbs/${filename}_${thumbSize}x${thumbSize}.webp`;
        break;
      // @ts-ignore - TS doesn't like the fallthrough but it's intentional
      case 'svg':
        setIsSVG(true);
      // eslint-disable-next-line no-fallthrough -- same as above
      default:
        path = props.logo.src;
    }

    getDownloadURL(ref(storage, `logos/${path}`)).then((url) => {
      setImage(url);
    });
  }, [props.logo.src]);

  if (!image) {
    return null;
  }

  if (isSVG) {
    return (
      <a href={props.logo.link} target="_blank" rel="noreferrer">
        <img
          src={image}
          alt={props.logo.alt}
          className="logo logo-svg"
          loading="lazy"
        />
      </a>
    );
  }

  return (
    <a href={props.logo.link} target="_blank" rel="noreferrer">
      <picture>
        <source srcSet={image} type="image/webp" />
        <img src={image} alt={props.logo.alt} className="logo" loading="lazy" />
      </picture>
    </a>
  );
}
