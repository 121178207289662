/**
 * Author: Jack Lusher (jack@lusher.digital)
 * Date:   20/10/2022
 * Time:   17:00
 *
 * Description:
 * This file contains the UserProvider component. This component is responsible
 * for providing Firebase Auth information to the application.
 *
 * Usage:
 * To use this component, you must import the useUser hook and use it in the element
 * you wish to access the Firebase Auth information from.
 *
 * Notes:
 * The useUser hook can only be used in an element that is a child (of any depth)
 * of an UserProvider component. These can be in the same file or in a different file.
 *
 * Example:
 * function App() {
 *   let {
 *     user,
 *     signInWithGoogle,
 *     signInWithPassword,
 *     createUserWithPassword,
 *     resetPassword,
 *     signOut,
 *   } = useFirebase();
 *
 *   return (
 *     ...
 *   );
 * }
 */

import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  User,
  GoogleAuthProvider,
  UserCredential,
} from 'firebase/auth';
import { auth } from './Firebase';

type UserContextType = {
  user: User | null | undefined;
  signInWithGoogle: () => Promise<UserCredential | void | undefined>;
  signInWithPassword: (
    email: string,
    password: string
  ) => Promise<UserCredential | void | undefined>;
  createUserWithPassword: (
    email: string,
    password: string
  ) => Promise<UserCredential | void | undefined>;
  resetPassword: (email: string) => Promise<UserCredential | void | undefined>;
  signOut: () => Promise<void>;
};

const UserContext = createContext<UserContextType>({
  user: undefined,
  signInWithGoogle: async () => {},
  signInWithPassword: async () => {},
  createUserWithPassword: async () => {},
  resetPassword: async () => {},
  signOut: async () => {},
});

export default function UserProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  let [user, setUser] = useState<User | null | undefined>(undefined);

  useEffect(() => auth.onAuthStateChanged(setUser), []);

  return (
    <UserContext.Provider
      value={{
        user,
        signInWithGoogle: async () => {
          if (auth) {
            const provider = new GoogleAuthProvider();
            provider.addScope('email');
            return signInWithPopup(auth, provider);
          }
        },
        signInWithPassword: async (email: string, password: string) => {
          if (auth) {
            return signInWithEmailAndPassword(auth, email, password);
          }
        },
        createUserWithPassword: async (email: string, password: string) => {
          if (auth) {
            return createUserWithEmailAndPassword(auth, email, password);
          }
        },
        resetPassword: async (email: string) => {
          if (auth) {
            return sendPasswordResetEmail(auth, email);
          }
        },
        signOut: async () => {
          if (auth) {
            return auth.signOut();
          }
        },
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const useUser = () => {
  return useContext(UserContext);
};
