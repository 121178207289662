import Speaker from '../routes/speakers/Speaker';
import Editable from '../widgets/editable/Editable';

function Name(props: { speaker: Speaker }): JSX.Element {
  const { speaker } = props;

  return (
    <>
      <Editable inline live path={`speakers/${speaker.id}:firstName`} />{' '}
      <Editable inline live path={`speakers/${speaker.id}:lastName`} />
      {speaker.postnominals && (
        <>
          {' '}
          <Editable inline live path={`speakers/${speaker.id}:postnominals`} />
        </>
      )}
    </>
  );
}

export default Name;
