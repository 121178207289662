import HubspotForm from '../../widgets/hubspot-form/HubspotForm';
import './Footer.scss';

function Footer() {
  return (
    <footer className="app-footer">
      <div className="app-footer-inner">
        <div className="app-footer-inner-upper">
          <HubspotForm
            region="eu1"
            portalId="26013527"
            formId="9b1e165c-0d65-472c-9619-5179cb12e4ea"
          />
        </div>
        <div className="app-footer-inner-lower">
          <p className="app-footer-copyright">
            &copy; {new Date().getFullYear()} Talking Performance, all rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
