import Hero from '../../widgets/hero/Hero';
import ImageCards from '../../widgets/image-cards/ImageCards';
import Marquee from '../../widgets/marquee/Marquee';
import './Home.scss';

function Home() {
  return (
    <div className="home">
      <Hero />
      <Marquee />
      <div className="home__spacer" />
      <ImageCards />
    </div>
  );
}

export default Home;
