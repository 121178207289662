import {
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import emptyImage from '../../../images/empty-avatar.png';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { useEffect, useRef, useState } from 'react';
import { firestore, storage } from '../../../providers/Firebase';
import { useUser } from '../../../providers/UserProvider';
import NewsItem from '../NewsItem';
import './AddNews.scss';

function AddNews() {
  const { user } = useUser();

  const imageRef = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState<string>(emptyImage);
  const [imageUploadProgress, setImageUploadProgress] = useState<number>(0);

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [type, setType] = useState<NewsItem['type']>('news');
  const [image, setImage] = useState<File>();

  function generateSlug() {
    if (!title) {
      return 'article-title';
    }

    const slug = `${title.toLowerCase().replace(/[^a-z0-9]/g, '-')}`
      .replaceAll(/[\W\d]/g, '-')
      .replaceAll(/-+/g, '-')
      .replace(/^-/, '')
      .replace(/-$/, '');

    const newsCollection = collection(firestore, 'articles');
    const articleRef = doc(newsCollection, slug);

    getDoc(articleRef).then((doc) => {
      if (doc.exists()) {
        setError('Article already exists');
      }
    });

    return slug;
  }

  function generateURL() {
    return `${window.location.origin}/news/${generateSlug()}`;
  }

  useEffect(() => {
    // create the preview
    const objectUrl = image ? URL.createObjectURL(image) : emptyImage;
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);

  if (success) {
    return (
      <div className="layout-main">
        <h1>Success!</h1>
        <p>
          Your article has been submitted. You can view it at{' '}
          <a href={generateURL()}>{generateURL()}</a>
        </p>
      </div>
    );
  }

  if (submitting) {
    return <div>Submitting... {imageUploadProgress}%</div>;
  }

  return (
    <main className="layout-main">
      <form
        onSubmit={async (e) => {
          e.preventDefault();

          setSubmitting(true);

          if (!image) {
            setError('Please upload an image');
            setSubmitting(false);
            return;
          }

          const slug = generateSlug();
          const newsCollection = collection(firestore, 'articles');
          const articleRef = doc(newsCollection, slug);

          // Upload the image
          const imageRef = ref(
            storage,
            `articles/${slug}/image.${image.type.split('/')[1]}`
          );
          const uploadTask = uploadBytesResumable(imageRef, image);

          uploadTask.on('state_changed', (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setImageUploadProgress(progress);
          });

          uploadTask
            .then(async (snapshot) => {
              // Upload the speaker
              await setDoc(articleRef, {
                id: slug,
                title: title || null,
                content: content || null,
                type,
                image: snapshot.metadata.fullPath,
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
                createdBy: user!.uid,
                updatedBy: user!.uid,
              });

              setSubmitting(false);
              setSuccess(true);
            })
            .catch((e) => {
              setError(e.message);
              setSubmitting(false);
            });
        }}
      >
        {error && <div className="error">{error}</div>}
        <table>
          <caption>Add an Article</caption>
          <tbody>
            <tr>
              <td>
                <div className="image-upload">
                  <button
                    className="image-upload__button"
                    onClick={(e) => {
                      e.preventDefault();
                      setImage(undefined);
                      imageRef.current!.value = '';
                    }}
                  >
                    <img
                      className="image-upload__button__image"
                      src={preview}
                      alt="Preview"
                      width={100}
                      height={100}
                    />
                    {image && (
                      <span className="image-upload__button__label">
                        Remove
                      </span>
                    )}
                  </button>
                </div>
              </td>
              <td>
                <label htmlFor="image">Image</label>
                <input
                  ref={imageRef}
                  id="image"
                  type="file"
                  onChange={(e) => {
                    if (!e.target.files || e.target.files.length === 0) {
                      setImage(undefined);
                      return;
                    }
                    // I've kept this example simple by using the first image instead of multiple
                    setImage(e.target.files[0]);
                  }}
                  required
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <label htmlFor="urlSlug">URL Slug</label>
                <input
                  id="urlSlug"
                  type="text"
                  value={generateURL()}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <label htmlFor="title">Title</label>
                <input
                  id="title"
                  name="title"
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <label htmlFor="content">Content</label>
                <textarea
                  id="content"
                  name="content"
                  rows={10}
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  required
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <label htmlFor="type">Type</label>
                <div className="select">
                  <select
                    id="type"
                    name="type"
                    value={type}
                    onChange={(e) =>
                      setType(e.target.value as NewsItem['type'])
                    }
                  >
                    <option value="news">News</option>
                    <option value="interview">Interview</option>
                    <option value="insight">Insight</option>
                  </select>
                  <span className="focus"></span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <button type="submit">Add Article</button>
      </form>
    </main>
  );
}

export default AddNews;
