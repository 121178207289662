import FounderCard from '../../components/founder-card/FounderCard';
import ErrorBoundary from '../../widgets/error-boundary/ErrorBoundary';
import './About.scss';

function About() {
  return (
    <div className="about">
      <ErrorBoundary>
        <FounderCard />
      </ErrorBoundary>
    </div>
  );
}

export default About;
