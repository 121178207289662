import ImageCard from '../image-card/ImageCard';
import './ImageCards.scss';

function ImageCards() {
  return (
    <div className="image-cards">
      <ImageCard
        titlePath="pages/home:cards.0.title"
        defaultTitle="LATEST NEWS"
        descriptionPath="pages/home:cards.0.description"
        defaultDescription="News, interviews and high-performance secrets"
        image="web/charlesdeluvio-wn7dOzUh3Rs-unsplash.jpg"
        link="/news"
        cta="View"
      />
      <ImageCard
        titlePath="pages/home:cards.1.title"
        defaultTitle="SPEAKERS"
        descriptionPath="pages/home:cards.1.description"
        defaultDescription="View our roster of world-leading motivational speakers"
        image="web/chris-karidis-4KtFhKgj0jk-unsplash.jpg"
        link="/speakers"
        cta="Show me"
      />
      <ImageCard
        titlePath="pages/home:cards.2.title"
        defaultTitle="ABOUT US"
        descriptionPath="pages/home:cards.2.description"
        defaultDescription="The people and mission behind Talking Performance"
        image="web/md-duran-rE9vgD_TXgM-unsplash.jpg"
        link="/about"
        cta="Learn more"
      />
    </div>
  );
}

export default ImageCards;
