import './NewSpeaker.scss';
import { useEffect, useRef, useState } from 'react';
import emptyAvatar from '../../../images/empty-avatar.png';
import {
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../providers/UserProvider';
import { firestore, storage } from '../../../providers/Firebase';

function NewSpeaker() {
  const { user } = useUser();
  const navigate = useNavigate();

  const avatarRef = useRef<HTMLInputElement>(null);

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string>();

  const [avatar, setAvatar] = useState<File>();
  const [preview, setPreview] = useState<string>(emptyAvatar);
  const [avatarUploadProgress, setAvatarUploadProgress] = useState<number>(0);

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [postnominals, setPostnominals] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [baseRate, setMinRate] = useState<number>(0);
  const [maxRate, setMaxRate] = useState<number>(0);
  const [tagline, setTagline] = useState<string>('');
  const [bio, setBio] = useState<string>('');
  const [twitter, setTwitter] = useState<string>('');
  const [linkedIn, setLinkedin] = useState<string>('');
  const [website, setWebsite] = useState<string>('');

  function generateSlug() {
    const slug = `${firstName.toLowerCase() || 'first'}-${
      lastName.toLowerCase() || 'last'
    }`
      .replaceAll(/[\W\d]/g, '-')
      .replaceAll(/-+/g, '-')
      .replace(/^-/, '')
      .replace(/-$/, '');

    const speakersCollection = collection(firestore, 'speakers');
    const speakerRef = doc(speakersCollection, slug);

    getDoc(speakerRef).then((doc) => {
      if (doc.exists()) {
        setError('Speaker already exists');
      }
    });

    return slug;
  }

  function generateURL() {
    return `${window.location.origin}/speakers/${generateSlug()}`;
  }

  useEffect(() => {
    // create the preview
    const objectUrl = avatar ? URL.createObjectURL(avatar) : emptyAvatar;
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [avatar]);

  if (submitting) {
    return <div>Submitting... {avatarUploadProgress}%</div>;
  }

  return (
    <main className="layout-main">
      <form
        onSubmit={async (e) => {
          e.preventDefault();

          setSubmitting(true);

          if (!avatar) {
            setError('Avatar is required');
            setSubmitting(false);
            return;
          }

          const slug = generateSlug();
          const speakersCollection = collection(firestore, 'speakers');
          const speakerRef = doc(speakersCollection, slug);

          if ((await getDoc(speakerRef)).exists()) {
            setError('Speaker already exists');
            setSubmitting(false);
            return;
          }

          // Upload the avatar
          const avatarRef = ref(
            storage,
            `speakers/${slug}/avatar.${avatar.type.split('/')[1]}`
          );
          const uploadTask = uploadBytesResumable(avatarRef, avatar);

          uploadTask.on('state_changed', (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setAvatarUploadProgress(progress);
          });

          uploadTask
            .then(async (snapshot) => {
              // Upload the speaker
              await setDoc(speakerRef, {
                id: slug,
                firstName: firstName || null,
                lastName: lastName || null,
                postnominals: postnominals || null,
                email: email.toLowerCase() || null,
                baseRate: baseRate || null,
                maxRate: maxRate || null,
                tagline: tagline || null,
                bio: bio || null,
                twitter: twitter || null,
                linkedin: linkedIn || null,
                website:
                  website.replace(/^https?:\/\//, '').replace(/\/$/, '') ||
                  null,
                avatar: snapshot.metadata.fullPath,
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
                createdBy: user!.uid,
                updatedBy: user!.uid,
              });

              setSubmitting(false);
              navigate('/speakers');
            })
            .catch((e) => {
              setError(e.message);
              setSubmitting(false);
            });
        }}
      >
        {error && <div className="error">{error}</div>}
        <table>
          <caption>Add a Speaker</caption>
          <tbody>
            <tr>
              <td>
                <div className="image-upload">
                  <button
                    className="image-upload__button"
                    onClick={(e) => {
                      e.preventDefault();
                      setAvatar(undefined);
                      avatarRef.current!.value = '';
                    }}
                  >
                    <img
                      className="image-upload__button__image"
                      src={preview}
                      alt="Avatar preview"
                      width={100}
                      height={100}
                    />
                    {avatar && (
                      <span className="image-upload__button__label">
                        Remove
                      </span>
                    )}
                  </button>
                </div>
              </td>
              <td>
                <label htmlFor="avatar">Avatar</label>
                <input
                  ref={avatarRef}
                  id="avatar"
                  type="file"
                  onChange={(e) => {
                    if (!e.target.files || e.target.files.length === 0) {
                      setAvatar(undefined);
                      return;
                    }
                    // I've kept this example simple by using the first image instead of multiple
                    setAvatar(e.target.files[0]);
                  }}
                  required
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <label htmlFor="urlSlug">URL Slug</label>
                <input
                  id="urlSlug"
                  type="text"
                  value={generateURL()}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="firstName">First Name</label>
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  pattern="[A-Za-z \-']{1,32}"
                  required
                />
              </td>
              <td>
                <label htmlFor="lastName">Last Name</label>
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  value={lastName}
                  pattern="[A-Za-z \-']{1,32}"
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <label htmlFor="postnominals">Postnominals</label>
                <input
                  id="postnominals"
                  name="postnominals"
                  type="text"
                  value={postnominals}
                  onChange={(e) => setPostnominals(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="rate">Rate (base)</label>
                <input
                  id="rate"
                  type="number"
                  min={0}
                  step={1}
                  value={baseRate.toString() || 0}
                  pattern="\d+"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replaceAll(/\D/g, '');
                    setMinRate(parseInt(value, 10) || 0);
                  }}
                />
              </td>
              <td>
                <label htmlFor="rate">Rate (maximum)</label>
                <input
                  id="rate"
                  type="number"
                  min={0}
                  step={1}
                  value={maxRate.toString() || 0}
                  pattern="\d+"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replaceAll(/\D/g, '');
                    setMaxRate(parseInt(value, 10) || 0);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <label htmlFor="tagline">Tagline</label>
                <input
                  id="tagline"
                  name="tagline"
                  type="text"
                  value={tagline}
                  onChange={(e) => setTagline(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <label htmlFor="bio">Bio</label>
                <textarea
                  id="bio"
                  name="bio"
                  rows={5}
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                  required
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={email}
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <label htmlFor="twitter">Twitter</label>
                <input
                  id="twitter"
                  name="twitter"
                  type="text"
                  value={twitter && `@${twitter}`}
                  onChange={(e) => setTwitter(e.target.value.replace('@', ''))}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <label htmlFor="linkedin">LinkedIn</label>
                <input
                  id="linkedin"
                  name="linkedin"
                  type="text"
                  value={linkedIn}
                  onChange={(e) => setLinkedin(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <label htmlFor="website">Website</label>
                <input
                  id="website"
                  name="website"
                  type="text"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <button type="submit">Add Speaker</button>
      </form>
    </main>
  );
}

export default NewSpeaker;
